/** @jsx jsx */
import React, { useState, useEffect } from 'react'
import { css, jsx } from '@emotion/core'
import Button from '@material-ui/core/Button'
import * as firebase from "firebase/app";

const Check = ({userName, actionId}: {userName: string, actionId: string}) => {

  const [pressed, setPressed] = useState(false)

  const clicked = () => {
    setPressed(true)
    const ref = firebase.firestore().collection("presentations").doc('AsuEkwaOQIqyAIB5gPmh').collection('actions').doc(actionId);
    ref.update({
      participants: firebase.firestore.FieldValue.arrayUnion(userName)
    });
  }

  return (
    <div>
      <div><Button variant="contained" disabled={pressed} onClick={clicked}>Press Me ASAP!</Button></div>
    </div>
  ) 
}

export default Check