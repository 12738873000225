/** @jsx jsx */
import React, { useState, useEffect } from 'react'
import { css, jsx } from '@emotion/core'
import Button from '@material-ui/core/Button'
import * as firebase from "firebase/app";

const Actions = () => {

  const [viewers, setViewers] = useState(0)
  const [actionRef, setActionRef] = useState('')
  const [results, setResults] = useState([])

  useEffect(() => {
    const ref = firebase.firestore().collection("presentations").doc('AsuEkwaOQIqyAIB5gPmh').collection('viewers');

    ref.onSnapshot((doc: any) => {
      setViewers(doc.docs.length)
      // querySnapshot.forEach((doc) => {
      //     console.log(`${doc.id} => ${doc.data()}`);
      // });
    });
  }, [])

  const initListeningCheck = () => {
    const ref = firebase.firestore().collection("presentations").doc('AsuEkwaOQIqyAIB5gPmh').collection('actions');

    ref.add({
      type: 'LISTENING_CHECK',
      result: null,
      participants: []
    }).then((docRef) => {
      const actRef = firebase.firestore().collection("presentations").doc('AsuEkwaOQIqyAIB5gPmh');
      // Set the "capital" field of the city 'DC'
      
          //TODO: keep this from going over or under
          setActionRef(docRef.id);
          actRef.update({
              current_action: docRef.id
          })
    })
  }

  const initTimer = () => {
    const ref = firebase.firestore().collection("presentations").doc('AsuEkwaOQIqyAIB5gPmh').collection('actions');

    ref.add({
      type: 'TIMER',
      result: null,
      participants: []
    }).then((docRef) => {
      const actRef = firebase.firestore().collection("presentations").doc('AsuEkwaOQIqyAIB5gPmh');
      // Set the "capital" field of the city 'DC'
      
          //TODO: keep this from going over or under
          setActionRef(docRef.id);
          actRef.update({
              current_action: docRef.id
          })
    })
  }

  useEffect(() => {
    if(actionRef){
      const ref = firebase.firestore().collection("presentations").doc('AsuEkwaOQIqyAIB5gPmh').collection('actions').doc(actionRef)
      ref.onSnapshot((doc) => {
        const res = doc.data()
        setResults(res?.participants || [])
      })
    }
  }, [actionRef])
  
  const clearAction = () => {
    const ref = firebase.firestore().collection("presentations").doc('AsuEkwaOQIqyAIB5gPmh')
    ref.update({
      current_action: ''
    })
    setResults([])
  }

  return (
    <div>
      {results.length ? <div>results: {results.length}, {results.join(', ')}</div> : null}
      <div><Button onClick={initListeningCheck}>Listening Check</Button></div>
      <div><Button onClick={initTimer}>Timer</Button></div>
      <div><Button>Black Out</Button></div>
      <div><Button>Call On New Reader</Button></div>
      <div><Button onClick={clearAction}>Clear Current Action</Button></div>
    </div>
  ) 
}

export default Actions