/** @jsx jsx */
import React, { useState, useEffect } from 'react'
import { css, jsx } from '@emotion/core'
import Button from '@material-ui/core/Button'
import * as firebase from "firebase/app";



const Participation = ({activeSlide, setActiveSlide}: {activeSlide: number, setActiveSlide: any}) => {

  const [viewers, setViewers] = useState<any>([])
  const [feedback, setFeedback] = useState<any[]>([])

  useEffect(() => {
    const ref = firebase.firestore().collection("presentations").doc('AsuEkwaOQIqyAIB5gPmh').collection('viewers');

    ref.onSnapshot((doc: any) => {
      setViewers(doc.docs)
      // querySnapshot.forEach((doc) => {
      //     console.log(`${doc.id} => ${doc.data()}`);
      // });
    });
  }, [])

  useEffect(() => {
    const ref = firebase.firestore().collection("presentations").doc('AsuEkwaOQIqyAIB5gPmh').collection('responses').doc(activeSlide + '').collection('feedback')
    ref.onSnapshot((docs: any) => {
      const fArray: any[] = []
      docs.forEach((doc: any) => {
        fArray.push(doc.data())
      })

      setFeedback(fArray)
    })
  }, [
    activeSlide
  ])

  const moveSlides = (move: number) => {
    const ref = firebase.firestore().collection("presentations").doc('AsuEkwaOQIqyAIB5gPmh');
// Set the "capital" field of the city 'DC'

    //TODO: keep this from going over or under
    ref.update({
        active_slide: activeSlide + move
    })
    setActiveSlide((prev: number) => prev + move);
  }

  const clearParticipants = () => {
    const ref = firebase.firestore().collection("presentations").doc('AsuEkwaOQIqyAIB5gPmh').collection('viewers');

    ref.onSnapshot((docs) => {
      docs.forEach((doc) => {
        doc.ref.delete()
      })
    })
  }

  return (
    <div>
      <div>Viewers: {viewers.length}/{viewers.length}</div>
      <div>{
        viewers.map((v: any) => <div>* {v.data().name}</div>)  
      }</div>
      <div></div>
      {feedback.length ?
        <div style={{margin: 24}}>Feedback: {feedback.map(f => <div>{f.user_name}: {f.response}</div>)}</div>
      : null}
      <div>
        <Button variant="contained" onClick={clearParticipants}>Clear Participants</Button>
      </div>
    </div>
  )
}

export default Participation