import React, { useState, useEffect, ReactComponentElement, ReactElement } from 'react'
import Read from './slides/read'
import * as firebase from "firebase/app";
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Check from './actions/check'
import Timer from './actions/timer'
import { css } from '@emotion/core'
import Snackbar from '@material-ui/core/Snackbar';


const View = () => {

  useEffect(() => {
    const db = firebase.firestore();
    db.collection("presentations").doc('AsuEkwaOQIqyAIB5gPmh').onSnapshot((doc: any) => {
      // querySnapshot.forEach((doc) => {
      //     console.log(`${doc.id} => ${doc.data()}`);
      // });
        if(doc){
            setActiveSlide(doc.data().active_slide || 0);
            setCurrentAction(doc.data().current_action  || '')
        }
    });
    if(localStorage.getItem('userName')){
      joinMeeting()
    }
  }, [])

  const [userName, setUserName] = useState(localStorage.getItem('userName') || '')
  const [joined, setJoined] = useState(false)

  const [slides, setSlides] = useState([
    {
      type: 'READ',
      content: <div>
        <h1>Christmas!</h1>
      </div>
    },
    {
      type: 'QUESTION',
      content: <div>Favorite Christmas Movie?</div>
    },
    {
      type: 'QUESTION',
      content: <div>Favorite Christmas Tradition?</div>
    },
    {
      type: 'QUESTION',
      content: <div>Worst Christmas Gift?</div>
    },
    {
      type: 'QUESTION',
      content: <div>Best Christmas Gift?</div>
    },
    {
      type: 'READ',
      content: <div>
        <h1>Moroni 10: 8</h1>
        <div>
        And again, I exhort you, my brethren, that ye deny not the gifts of God, for they are many; and they come from the same God. And there are different ways that these gifts are administered; but it is the same God who worketh all in all; and they are given by the manifestations of the Spirit of God unto men, to profit them.
        </div>
      </div>
    },
    {
      type: 'READ',
      content: <div>
        <h1>Spiritual Gifts : Gifts of the Spirit</h1>
        <div>Underrated.</div>
        <br/>
        <div>Have you ever met someone with an obvious spiritual gift?</div>
      </div>
    }, 
    {
      type: 'QUESTION',
      content: <div>What spiritual gift do you want this year?</div>
    },
    {
      type: 'QUESTION',
      content: <div>What are you going to do in 2021 to earn that gift?</div>
    },  
    {
      type: 'READ',
      content: <div>
        <h1>Merry Christmas! GL HF with life!</h1>
      </div>
    },  
  ])

  const [activeSlide, setActiveSlide] = useState(0)
  const [currentAction, setCurrentAction] = useState('')
  const [actionType, setActionType] = useState('')
  const [response, setResponse] = useState('')

  const submitResponse = () => {
    const ref = firebase.firestore().collection("presentations").doc('AsuEkwaOQIqyAIB5gPmh').collection('responses').doc(activeSlide + '').collection('feedback')
// Set the "capital" field of the city 'DC'

    //TODO: keep this from going over or under
    ref.add({
        user_name: userName,
        response: response
    })
    setResponse('')
  }

  useEffect(() => {
    const db = firebase.firestore();
    if(currentAction){
      db.collection("presentations").doc('AsuEkwaOQIqyAIB5gPmh').collection('actions').doc(currentAction || '').onSnapshot((doc: any) => {
        setActionType(doc.data().type)
      });
    }
  }, [currentAction])

  const getSlide = (slide: {type: string, content: ReactElement}) : ReactElement => {
    if(slide.type === 'QUESTION'){
      return <div>
        <div>{slide.content}</div>
        <div style={{marginTop: 16}}><TextField variant="outlined" value={response} onChange={(e) => {setResponse(e.target.value)}} placeholder="Answer..."/></div>
        <div style={{marginTop: 16}}><Button onClick={submitResponse}>Submit</Button></div>
      </div>
    }
    return <div>{slide.content}</div>
  }

  const getAction = () => {
    if(actionType === 'LISTENING_CHECK'){
      return <Check userName={userName} actionId={currentAction}/>
    }
    if(actionType === 'TIMER'){
      return <Timer userName={userName} actionId={currentAction}/>
    }
    return <div>ACTION IS HEREE</div>
  }

  const joinMeeting = () => {
    setJoined(true)
    localStorage.setItem('userName', userName)

    const ref = firebase.firestore().collection("presentations").doc('AsuEkwaOQIqyAIB5gPmh').collection('viewers');
    // Set the "capital" field of the city 'DC'
    
        //TODO: keep this from going over or under
       ref.add({
          name: userName,
      })
  }

  return (
    <> {
      !joined ? <div>
        Please enter your name to join the presentive.
        <div style={{marginTop: 16}}>
          <TextField autoFocus variant="outlined" placeholder="Name" onChange={(e) => {setUserName(e.target.value)}} value={userName}></TextField>
        </div>
        <div style={{marginTop: 16}}>
          <Button variant="outlined" disabled={!userName} onClick={() => {joinMeeting()}}>Join</Button>
        </div>
      </div> :
        <div style={{display: 'flex', position: 'relative', fontSize: 24, overflow: 'auto', height: '100%', alignItems: 'center', padding: '0 24px'}}>
          {
            //hack shouldn't increment numbers
            getSlide(slides[activeSlide] ? slides[activeSlide] : slides[0])
          }
          {
            currentAction ? 
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={!!currentAction}
              onClose={() => {}}
              message={<div>{getAction()}</div>}
              key={"action"}
            />
            : null
          }
        </div>
    }
    </>
  )
}

export default View