/** @jsx jsx */
import React, { useState, useEffect } from 'react'
import { css, jsx } from '@emotion/core'
import Button from '@material-ui/core/Button'
import * as firebase from "firebase/app";

const Timer = ({userName, actionId}: {userName: string, actionId: string}) => {

  const [time, setTime] = useState(30)

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(prev => prev - 1)
    }, 1000)
  }, [])

  return (
    <div>
      <div>Remaining Time: {time}</div>
    </div>
  ) 
}

export default Timer