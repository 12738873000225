/** @jsx jsx */
import React, { useState } from 'react'
import { css, jsx } from '@emotion/core'
import Viewer from '../viewer/view'
import Controls from './controls'
import Participation from './participation'
import Actions from './actions'
import Button from '@material-ui/core/Button'
import * as firebase from "firebase/app";
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';

import HomeIcon from '@material-ui/icons/Home'
import DecksIcon from '@material-ui/icons/DynamicFeed'
import ProfileIcon from '@material-ui/icons/Person'
import ReportsIcon from '@material-ui/icons/BarChart'
import LogoutIcon from '@material-ui/icons/ExitToApp'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));


const View = () => {

  const classes = useStyles();

  const [activeSlide, setActiveSlide] = useState(0)
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(prev => !prev);
  };

  return (
    <div css={css`
      background-color: #1b1b1b;
      display: flex;
      color: white;
      `}>
        <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div css={css`display: flex; justify-content: center;`}>
           <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <div>
          <div css={css`padding: 24px 0;`}><HomeIcon/></div>       
          <div css={css`padding: 24px 0;`}><DecksIcon /></div>  
          <div css={css`padding: 24px 0;`}><ReportsIcon /></div>       
          <div css={css`padding: 24px 0;`}><ProfileIcon /></div>
          <div css={css`padding: 24px 0;`}><LogoutIcon /></div>
        </div>
      </Drawer>
      <div css={css`flex: 1; display: flex; flex-direction: column; `}>
        <div css={css`flex: 1; display: flex; width: 100%; max-height: 60%; overflow: hidden; flex-direction: column;`}>
          <div css={css`flex: 1; border-right: 1px solid white; display: flex; align-items: center; justify-content: center; height: 100%; overflow: hidden; box-sizing: border-box;`} className={"presenter-presentation-view"}>
            <Viewer/>
          </div>
          <div>
            <Controls activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
          </div>
        </div>
        <div css={css`flex: 1; display: flex; width: 100%`}>
        <div css={css`flex: 1; border: 1px solid white; display: flex; flex-direction: column; align-items: center; justify-content: center;`}>
          <Participation activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
        </div>
          <div css={css`flex: 1; border: 1px solid white; display: flex; align-items: center; justify-content: center;`}>
            Presenter Notes Section: Have the kids read a section each.
            <div>Timer</div>
            <div>Next Slide Preview</div>
          </div>
        </div> 
      </div>
      <div css={css`flex-shrink: 1; height: 100vh; min-width: 73px;`}>
        <Actions />
      </div>
        
     </div>
  )
}

export default View