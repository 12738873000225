/** @jsx jsx */
import React, { useState, useEffect } from 'react'
import { css, jsx } from '@emotion/core'
import Button from '@material-ui/core/Button'
import * as firebase from "firebase/app";

import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'



const Controls = ({activeSlide, setActiveSlide}: {activeSlide: number, setActiveSlide: any}) => {

  const [viewers, setViewers] = useState(0)
  const [feedback, setFeedback] = useState<any[]>([])

  useEffect(() => {
    const ref = firebase.firestore().collection("presentations").doc('AsuEkwaOQIqyAIB5gPmh').collection('viewers');

    ref.onSnapshot((doc: any) => {
      setViewers(doc.docs.length)
      // querySnapshot.forEach((doc) => {
      //     console.log(`${doc.id} => ${doc.data()}`);
      // });
    });
  }, [])

  useEffect(() => {
    const ref = firebase.firestore().collection("presentations").doc('AsuEkwaOQIqyAIB5gPmh').collection('responses').doc(activeSlide + '').collection('feedback')
    ref.onSnapshot((docs: any) => {
      const fArray: any[] = []
      docs.forEach((doc: any) => {
        fArray.push(doc.data())
      })

      setFeedback(fArray)
    })
  }, [
    activeSlide
  ])

  const moveSlides = (move: number) => {
    const ref = firebase.firestore().collection("presentations").doc('AsuEkwaOQIqyAIB5gPmh');
// Set the "capital" field of the city 'DC'

    //TODO: keep this from going over or under
    ref.update({
        active_slide: activeSlide + move
    })
    setActiveSlide((prev: number) => prev + move);
  }

  const clearParticipants = () => {
    const ref = firebase.firestore().collection("presentations").doc('AsuEkwaOQIqyAIB5gPmh').collection('viewers');

    ref.onSnapshot((docs) => {
      docs.forEach((doc) => {
        doc.ref.delete()
      })
    })
  }

  return (
    <div css={css`display: flex; flex-shrink: 1; height: 45px; border-right: 1px solid white; border-top: 1px solid white; align-items: center;`}>
      <div css={css`flex: 1; text-align: left;`}><Button onClick={() => {moveSlides(-1)}}>
        <ChevronLeft/> Previous Slide</Button>
      </div>
      <div css={css`flex: 1;`}><Button>Skip to Slide</Button></div>
      <div css={css`flex: 1; text-align: right;`}><Button onClick={() => {moveSlides(1)}}>Next Slide <ChevronRight /> </Button></div>
    </div>
  )
}

export default Controls