/** @jsx jsx */
import React from 'react';
import './App.css';
import {   BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { css, jsx } from '@emotion/core'
import * as firebase from "firebase/app";
import "firebase/firestore";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';



//comps
import Viewer from './viewer/view'
import Presenter from './presenter/view'

const firebaseConfig = {
  apiKey: "AIzaSyC76sLouh7zktT5wgUzqKkALKnCAEKMaWs",
  authDomain: "presentive-b6622.firebaseapp.com",
  databaseURL: "https://presentive-b6622.firebaseio.com",
  projectId: "presentive-b6622",
  storageBucket: "presentive-b6622.appspot.com",
  messagingSenderId: "304426303464",
  appId: "1:304426303464:web:839fdc5e1ab8c214"
};

firebase.initializeApp(firebaseConfig);

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

function App() {
  
  return (
    <div className="App">
          <ThemeProvider theme={theme}>

      <Router>
        <Switch>
          <Route path="/" exact>
          <div css={css`height: calc(100vh - 48px);
      width: calc(100vw - 48px);
      background-color: #1b1b1b;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      color: white;
      padding: 24px;
      `}>              <Viewer/>
            </div>
          </Route>
          <Route path="/presenter">
            <Presenter/>
          </Route>
        </Switch>
      </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
